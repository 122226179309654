@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400&family=Poppins:wght@400;600&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


html {
  font-size: 62.5%;
}


body {
  background-color: rgb(219, 230, 253);
}


h1 {
  font-size: 3.2rem;
  margin: 2rem 0;
  text-align: center;
}


h2 {
  font-family: "IBM Plex Serif", serif;
  font-size: 2.4rem;
}


p {
  margin: 2rem 0;
  color: black;
  font-weight: 400;
  font-size: 1.6rem;
  display: inline-block;
}


.stories-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  padding-bottom: 10px;
}

.card {
  min-width: 20rem;
  width: 50vw;
  padding: 4rem;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
  color: #15133c;
}

.sub-card {
  font-size: 1.3rem;
}


.card-button {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.card-button a {
  color: #92b4ec;
  font-weight: 400;
  font-size: 1.6rem;
}


.card-button a:last-child {
  color: red;
}


form {
  display: grid;
  place-items: center;
}


input {
  padding: 1rem;
  min-width: 40rem;
  font-size: 2rem;
  text-transform: capitalize;
  border: none;
  border-bottom: 0.2rem solid #15133c;
  outline: none;
}


.pagination-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


button {
  padding: 0.8rem 2.2rem;
  border: none;
  font-size: 1.6rem;
  background-color: #15133c;
  color: #ffffff;
  margin: 3rem;
  cursor: pointer;
  display: inline-block;
}


/* responsive layout  */


@media (max-width: 998px) {
  html {
    font-size: 56%;
  }


  .card {
    min-width: 45rem;
    padding: 2.5rem;
  }
}


@media (max-width: 768px) {
  html {
    font-size: 51%;
  }


  .card {
    min-width: 35rem;
    padding: 2rem;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.spinner {
  border: 8px solid rgba(17, 159, 253, 0);
  border-top: 8px solid #000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
