/* Footer.css */

.footer {
    background-color: #6995f1;
    color: #ffffff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "IBM Plex Serif", serif;
  }
  
  .footer-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .footer-column {
    display: flex;
    align-items: center;
  }

  .footer-column .copy-right {
    font-size: 1.3rem;
    /* bold */
    color: rgb(3 0 74 / 50%)
    
  }
  
  .footer-column.links {
    flex-grow: 1;
    justify-content: center;
  }
  
  .footer-column button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin: 0;
  }
  

  .social-icons img {
    width: 35px;
    height: 35px;
    margin: 5px
  }
  
 /* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 800px; /* Increased max-width for larger content */
    max-height: 80%; /* Set maximum height for modal content */
    overflow-y: auto; /* Enable vertical scrolling */
    text-align: left;
    position: relative;
  }
  
  .modal-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .modal-content .modal-points {
    padding: 20px;
  }