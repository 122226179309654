@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400&family=Poppins:wght@400;600&display=swap");

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6995f1;
  color: white;
  padding: 10px 20px;
  font-family: "IBM Plex Serif", serif;
  font-size: 2.4rem;
  position: fixed;
  width: 100%;
  /* z-index: 1000; */
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 50px;
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  text-decoration: none;
  color: white;
}

.nav-menu {
  list-style-type: none;
  display: flex;
  align-items: center;
  font-size: 2.4rem;
}

.nav-item {
  margin-right: 20px;
}

.nav-item a {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.nav-item a:hover {
  background-color: #467ef1;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  display: block;
  font-size: 2rem;
}

.dropdown-content a:hover {
  background-color: #6896c1;
}
